import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import ListTimestamp from '../../components/Card/ListTimestamp';
import Loading from 'components/Loading';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useForm, Controller } from 'react-hook-form';
import OtRequireForm from 'components/Forms/OtRequireForm';
import { Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function Timestamp({ title, subtitle }) {
  const dispatch = useDispatch();
  const timestamp = useSelector((state) => state.timestamp);
  const me = useSelector((state) => state.me);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.timestampCheckin({ me: me?.userData?._id }));

    return () => {};
  }, []);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderButton = () => (
    <ListTimestamp timestamp={timestamp} setOpen={setOpen} />
  );

  const onsubmitOt = async (data) => {
    const confirm = window.confirm('ยืนยันข้อมูล');
    if (confirm) {
      const datasubmit = {
        ot_status: {
          require: true,
          description: data?.description,
          status: 'WAITING',
        },
      };
      await dispatch(actions.timestampOtRequire(timestamp?.id, { datasubmit }));
      dispatch(actions.timestampCheckin({ me: me?.userData?._id }));
      reset();
      handleClose();
    }
  };

  const renderOtrequire = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div className=" font-bold text-theme-600 ">ขออนุมัติ OT</div>
          <form onSubmit={handleSubmit(onsubmitOt)}>
            <OtRequireForm Controller={Controller} control={control} />
            <div className="flex justify-center">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );

  if (
    !timestamp?.isLoading &&
    timestamp?.isCompleted &&
    !me?.isLoading &&
    me?.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div>{renderButton()}</div>
        {renderOtrequire()}
      </div>
    );
  }
  return <Loading isLoading />;
}
